import http from '../configs/http-common';
import IFilters from '../types/Filters';
import IPatient from '../types/Patient';
import IPatientQuery from '../types/PatientQuery';
import IStatusCounter from '../types/StatusCounter';

const URL = '/patients'
const URL_filters = '/filters'
const URL_status_counter = '/patients-status-counter'

const getPatients = async (name: string, statuses: string[], portalIds: number[], organizationIds: number[], serviceIds: number[], aoiIds: number[], studyIds: number[], inChargeIds: number[], reviewerIds: number[], requestedFrom: string | undefined, requestedTo: string | undefined, actionRequired: boolean, page: number, size: number): Promise<{
    content: IPatient[];
    totalElements: number;
    totalPages: number;
}> => {
    const queryDto: IPatientQuery = {
        name,
        statuses,
        portalIds,
        organizationIds,
        serviceIds,
        aoiIds,
        studyIds,
        inChargeIds,
        reviewerIds,
        requestedFrom,
        requestedTo,
        actionRequired,
        page,
        size,
    };

    const response = await http.post<{
        content: IPatient[];
        totalElements: number;
        totalPages: number;
    }>(URL, queryDto);

    return response.data;
};

const getFilters = async (): Promise<IFilters> => {
    const response = await http.get<IFilters>(URL_filters);
    return response.data;
};

const getStatusCounter = async (): Promise<IStatusCounter> => {
    const response = await http.get<IStatusCounter>(URL_status_counter);
    return response.data;
};

const post = async (data: IPatient): Promise<IPatient> => {
    const response = await http.post<IPatient>(URL, data);
    return response.data;
};


http.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error: Error) {
        return Promise.reject(error);
    }
);

const PatientService = {
    getPatients,
    getFilters,
    getStatusCounter,
    post,
};

export default PatientService;
