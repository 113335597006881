import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext';
import Login from '../views/Login/Login';
import NotFound from '../views/NotFound/NotFound';
import Patients from '../views/Patients/Patients';
import { ProtectedRoute } from './ProtectedRoutes';

const PortalRoutes = () => {
  const protectedRoute = (path: string, element: React.ReactElement) => {
    return (
      <Route path={path} element={
        <ProtectedRoute>
          {element}
        </ProtectedRoute>
      } />
    )
  }
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          {protectedRoute('/dashboard', <Patients />)}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default PortalRoutes;
