
import { Container, Flex } from '@mantine/core';
import { DateValue } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import FilterOptionsCollapseButton from '../../components/Filters/FilterOptionsCollapseButton';
import FilterOptionsCollapseContent from '../../components/Filters/FilterOptionsCollapseContent';
import FilterStateActionRequiredButton from '../../components/Filters/FilterStateActionRequiredButton';
import FilterStateButtons from '../../components/Filters/FilterStateButtons';
import { HeaderMenu } from '../../components/HeaderMenu/HeaderMenu';
import SearchNameInput from '../../components/SearchNameInput';
import PatientService from '../../services/PatientService';
import IFilters from '../../types/Filters';
import IPatient from '../../types/Patient';
import IStatusCounter from '../../types/StatusCounter';
import PatientsList from './PatientsList/PatientsList';

const allStates = ['PENDING', 'ACCEPTED', 'PROCESSING', 'REVIEWING', 'RELEASED', 'QUARANTINE'];
const itemsPerPage = 100;

const Patients = () => {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [searchName, setSearchName] = useState<string>('');
  const [selectedStates, setSelectedStates] = useState<string[]>(allStates);
  const [selectedPortals, setSelectedPortals] = useState<number[]>([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState<number[]>([]);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [selectedAois, setSelectedAois] = useState<number[]>([]);
  const [selectedStudies, setSelectedStudies] = useState<number[]>([]);
  const [selectedIncharge, setSelectedIncharge] = useState<number[]>([]);
  const [selectedReviewers, setSelectedReviewers] = useState<number[]>([]);
  const [requestedFrom, setRequestedFrom] = useState<string | null>(null);
  const [requestedTo, setRequestedTo] = useState<string | null>(null);
  const [actionRequired, setActionRequired] = useState<boolean>(false);
  const [statusCounter, setStatusCounter] = useState<IStatusCounter>({
    all: 0,
    pending: 0,
    accepted: 0,
    processing: 0,
    reviewing: 0,
    released: 0,
    quarantine: 0,
    actionRequired: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>();
  const [opened, { toggle }] = useDisclosure(false);
  const isAllSelected = selectedStates.length === allStates.length && !actionRequired;

  const createPatientQuery = (page: number) => ({
    name: searchName,
    statuses: selectedStates,
    portalIds: selectedPortals,
    organizationIds: selectedOrganizations,
    serviceIds: selectedServices,
    aoiIds: selectedAois,
    studyIds: selectedStudies,
    inChargeIds: selectedIncharge,
    reviewerIds: selectedReviewers,
    requestedFrom: requestedFrom ?? undefined,
    requestedTo: requestedTo ?? undefined,
    actionRequired: actionRequired,
    page: page - 1,
    size: itemsPerPage
  });

  const updatePatientsList = (newPatients: any[], reset: boolean, prevPatients: any[]) => {
    if (reset) {
      return newPatients;
    } else {
      return [...prevPatients, ...newPatients.filter(patient => !prevPatients.some(p => p.id === patient.id))];
    }
  };

  const fetchPatients = async (page: number, reset: boolean = false) => {
    if (selectedStates.length === 0 && !actionRequired) {
      setPatients([]);
      return;
    }
    setLoading(true);
    try {
      const patientQuery = createPatientQuery(page);
      const data = await PatientService.getPatients(
        patientQuery.name,
        patientQuery.statuses,
        patientQuery.portalIds,
        patientQuery.organizationIds,
        patientQuery.serviceIds,
        patientQuery.aoiIds,
        patientQuery.studyIds,
        patientQuery.inChargeIds,
        patientQuery.reviewerIds,
        patientQuery.requestedFrom,
        patientQuery.requestedTo,
        patientQuery.actionRequired,
        patientQuery.page,
        patientQuery.size
      );
      setPatients(prevPatients => updatePatientsList(data.content, reset, prevPatients));
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Failed to fetch patients', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPatients([]);
    setCurrentPage(1);
    fetchPatients(1, true);
  }, [searchName, selectedStates, selectedPortals, selectedOrganizations, selectedServices, selectedAois, selectedStudies, selectedIncharge, selectedReviewers, requestedFrom, requestedTo, actionRequired]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        >= document.documentElement.scrollHeight - 60
      ) {
        if (!loading && currentPage < totalPages) {
          setCurrentPage(prevPage => prevPage + 1);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, currentPage, totalPages]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchPatients(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    const fetchFiltersAndStatus = async () => {
      try {
        const filtersData = await PatientService.getFilters();
        setFilters(filtersData);

        const statusCounterData = await PatientService.getStatusCounter();
        setStatusCounter(statusCounterData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchFiltersAndStatus();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  const handleStateChange = (state: string | string[]) => {
    setActionRequired(false);
    if (Array.isArray(state)) {
      setSelectedStates(state);
    } else if (state === 'All') {
      setSelectedStates(isAllSelected ? [] : allStates);
    } else {
      setSelectedStates(prevStates => {
        if (prevStates.includes(state)) {
          return prevStates.filter(s => s !== state);
        } else {
          return [...prevStates, state];
        }
      });
    }
  };

  const handleActionRequiredChange = () => {
    if (!actionRequired) {
      setSelectedStates([]);
    } else {
      setSelectedStates(allStates);
    }
    setActionRequired(!actionRequired);
  };

  const handleFilterChange = (type: string, selectedValues: number[]) => {
    switch (type) {
      case 'portalIds':
        setSelectedPortals(selectedValues);
        break;
      case 'organizationIds':
        setSelectedOrganizations(selectedValues);
        break;
      case 'serviceIds':
        setSelectedServices(selectedValues);
        break;
      case 'aoiIds':
        setSelectedAois(selectedValues);
        break;
      case 'studyIds':
        setSelectedStudies(selectedValues);
        break;
      case 'inChargeIds':
        setSelectedIncharge(selectedValues);
        break;
      case 'reviewerIds':
        setSelectedReviewers(selectedValues);
        break;
      default:
        break;
    }
  }

  const formatDateToISO = (date: Date): string => {
    const offset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - offset);

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}T00:00:00`;
  };

  const handleDateChange = (value: DateValue, setDate: React.Dispatch<React.SetStateAction<string | null>>) => {
    if (value) {
      const date = new Date(value);
      const isoDate = formatDateToISO(date);
      setDate(isoDate);
    } else {
      setDate(null);
    }
  };

  const handleRequestedFromChange = (value: DateValue) => {
    handleDateChange(value, setRequestedFrom);
  };

  const handleRequestedToChange = (value: DateValue) => {
    handleDateChange(value, setRequestedTo);
  };

  return (
    <div>
      <HeaderMenu></HeaderMenu>
      <Container size="xxl">
        <Flex
          justify="space-between"
          mb='1rem'
        >
          <FilterStateButtons
            allStates={allStates}
            selectedStates={selectedStates}
            isAllSelected={isAllSelected}
            onStateChange={handleStateChange}
            statusCounter={statusCounter}
          />
          {statusCounter.actionRequired > 0 &&
            <FilterStateActionRequiredButton statusCounter={statusCounter} actionRequired={actionRequired} onActionRequiredChange={handleActionRequiredChange} />
          }
          <FilterOptionsCollapseButton opened={opened} toggle={toggle} />
          <div style={{ flexGrow: 1 }}></div>
          <SearchNameInput
            searchName={searchName}
            onSearchChange={handleSearchChange}
            clearSearch={() => setSearchName('')}
          />
        </Flex>
        {filters && <FilterOptionsCollapseContent opened={opened} filters={filters} onFilterChange={handleFilterChange} onRequestedFromChange={handleRequestedFromChange} onRequestedToChange={handleRequestedToChange} />}
        <PatientsList
          patients={patients}
        />
      </Container>
    </div>
  );
};

export default Patients;
