import { Avatar, Badge, Center, Table, Text } from '@mantine/core';
import React from 'react';
import IPatient from '../../../types/Patient';

interface PatientsListProps {
    patients: IPatient[];
}

const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
};

const getInitials = (name: string) => {
    return name.split(' ')
        .map(word => word[0].toUpperCase())
        .join('');
};

const PatientsList: React.FC<PatientsListProps> = ({ patients }) => {

    if (patients.length === 0) {
        return <Center mt='3rem'>
            <Text size="lg">
                No patients found.
            </Text>
        </Center>;
    }

    const rows = patients.map((patient) => (
        <Table.Tr key={patient.id}>
            <Table.Td><Badge variant="outline" color="blue">{patient.status}</Badge></Table.Td>
            <Table.Td>{formatDate(new Date(patient.requestedFor))}</Table.Td>
            <Table.Td>{patient.name}</Table.Td>
            <Table.Td>{patient.region.toUpperCase()}</Table.Td>
            <Table.Td>{patient.service}</Table.Td>
            <Table.Td>{patient.aoi}</Table.Td>
            <Table.Td>{patient.study}</Table.Td>
            <Table.Td>
                {patient.etiologies.map((etiology) => (
                    <Badge key={etiology} color="grey" me="xs">{etiology}</Badge>
                ))}
            </Table.Td>

            <Table.Td>

            </Table.Td>
            <Table.Td>
                {patient.inCharge !== null && (
                    <Avatar src={null} alt="no image here" color="indigo">{getInitials(patient.inCharge)}</Avatar>
                )}
            </Table.Td>
            <Table.Td>
                {patient.reviewer !== null && (
                    <Avatar src={null} alt="no image here" color="blue">{getInitials(patient.reviewer)}</Avatar>
                )}
            </Table.Td>
            <Table.Td>
                {patient.deliveryDate !== null && (
                    formatDate(new Date(patient.deliveryDate))
                )}
            </Table.Td>
        </Table.Tr >
    ));

    return (
        <Table striped highlightOnHover>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Status</Table.Th>
                    <Table.Th>Requested</Table.Th>
                    <Table.Th>Patient</Table.Th>
                    <Table.Th>Region</Table.Th>
                    <Table.Th>Service</Table.Th>
                    <Table.Th>Area of interest</Table.Th>
                    <Table.Th>Study</Table.Th>
                    <Table.Th>Etiology</Table.Th>
                    <Table.Th>Modalities</Table.Th>
                    <Table.Th>CA in charge</Table.Th>
                    <Table.Th>Reviewer</Table.Th>
                    <Table.Th>Delivery date</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
        </Table>
    )
}

export default PatientsList;
