import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL as string;
export const LOGIN_URL = process.env.REACT_APP_AUTH_URL as string;
export const API_V1_URL = BASE_URL + "/api/v1";

export default axios.create({
  baseURL: API_V1_URL,
  headers: {
    "Content-type": "application/json",
  }
});

