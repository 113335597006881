import React from 'react';
import PortalRoutes from './routes/Routes';

const App = () => {
  return (
    <div>
      <PortalRoutes></PortalRoutes>
    </div>
  );
};

export default App;
