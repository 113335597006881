import { Button, Flex, Indicator } from '@mantine/core';
import React from 'react';
import IStatusCounter from '../../types/StatusCounter';

interface FilterStateActionRequiredButtonProps {
    statusCounter: IStatusCounter;
    actionRequired: boolean;
    onActionRequiredChange: () => void;
}

const FilterStateActionRequiredButton: React.FC<FilterStateActionRequiredButtonProps> = ({ statusCounter, actionRequired, onActionRequiredChange }) => {
    return (
        <Flex>
            <Indicator label={statusCounter.actionRequired} size={20} color="red">
                <Button
                    variant="default"
                    style={{ opacity: actionRequired ? 1 : 0.5 }}
                    onClick={() => onActionRequiredChange()}
                    ml="1rem"
                >
                    Action required
                </Button>
            </Indicator>
        </Flex>
    );
};

export default FilterStateActionRequiredButton;

