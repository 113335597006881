import { jwtDecode } from 'jwt-decode';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IUser from '../types/User';

type IAuthContext = {
    user: IUser | null;
    login: (token: string) => void;
    logout: () => void;
    loading: boolean;
};


const initialValue = {
    user: null,
    login: () => { },
    logout: () => { },
    loading: true
}

export const AuthContext = createContext<IAuthContext>(initialValue);

type AuthProviderProps = {
    children: ReactNode;
};

interface JwtPayload {
    first_name: string;
    last_name: string;
    [key: string]: any;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<IUser | null>(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                console.log('Token found:', token);
                const decodedToken: JwtPayload = jwtDecode<JwtPayload>(token);

                const user: IUser = {
                    id: 1,
                    first_name: decodedToken.first_name || '',
                    last_name: decodedToken.last_name || '',
                    token: token,
                    name: ''
                }
                setUser(user);
            }
            catch (error) {
                setUser(null);
                console.log("Token error " + error);
            }
        }
        else {
            setUser(null);
        }
        setLoading(false);
    }, []);

    const login = (token: string) => {
        localStorage.setItem('token', token);
        const user: IUser = {
            id: 1,
            first_name: "",
            last_name: "",
            token: token,
            name: ''
        }
        setUser(user);
        navigate("/dashboard");
    };

    const logout = () => {
        localStorage.removeItem('token');
        console.log("Logout")
        setUser(null);
        navigate("/");
    };

    const contextValue = { user, login, logout, loading };
    console.log('My user ' + user);

    return (
        <AuthContext.Provider value={contextValue} >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
