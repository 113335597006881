import { DateInput, DateValue } from '@mantine/dates';
import React from 'react';
import classes from './FilterOptions.module.css';

interface FilterOptionsDateInputProps {
    label: string;
    onChange: (value: DateValue) => void;
    placeholder?: string;
    mt?: string;
}

const FilterOptionsDateInput: React.FC<FilterOptionsDateInputProps> = ({ label, onChange, placeholder = 'Select a date', mt = '0' }) => {
    return (
        <DateInput
            onChange={onChange}
            valueFormat="DD/MM/YYYY"
            label={label}
            classNames={{ section: classes.section, input: classes.input, weekday: classes.weekday, day: classes.day }}
            placeholder={placeholder}
            clearable
            mt={mt}
            popoverProps={{
                styles: {
                    dropdown: {
                        backgroundColor: '#040d3b',
                    },
                },
            }}
        />
    );
};

export default FilterOptionsDateInput;
