import { createTheme, MantineTheme } from '@mantine/core';

const theme = createTheme({
  colors: {
    // Add your color
    'primary': [
      "#e9f4ff",
      "#d5e5fa",
      "#a8c8f1",
      "#79aaeb",
      "#5390e5",
      "#3c7fe1",
      "#2d77e1",
      "#2065c9",
      "#155ab4",
      "#004da0"

    ],
    dark: [
      "#202020",
      "#161A29",
      "#0D1432",
      "#040D3B",
      "#101848",
      "#1D2255",
      "#2B2D62",
      "#3A3A6E",
      "#48487B",
      "#575787",
    ],
  }



});

const resolver = (theme: MantineTheme) => ({
  variables: {

  },
  dark: {
    "--mantine-color-scheme": "#040d3b",
    "--mantine-color-text": "#FFF",
    "--mantine-pill-label": "#FFF",
  },

  light: {

  }
});

export { resolver, theme };

