import { Button, Center } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import React from 'react';

interface FilterOptionsCollapseButtonProps {
    opened: boolean;
    toggle: () => void;
}

const FilterOptionsCollapseButton: React.FC<FilterOptionsCollapseButtonProps> = ({ opened, toggle }) => {
    return (
        <Center>
            <Button
                onClick={toggle}
                rightSection={opened ? <IconChevronUp size={16} data-testid="icon-chevron-up" /> : <IconChevronDown size={16} data-testid="icon-chevron-down" />}
                variant="transparent"
            >
                Filters
            </Button>
        </Center>
    );
};

export default FilterOptionsCollapseButton;