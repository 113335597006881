import { Collapse, Grid } from '@mantine/core';
import React from 'react';
import IFilters from '../../types/Filters';
import FilterOptionsDateInput from './FilterOptionsDateInput';
import FilterOptionsMultiSelect from './FilterOptionsMultiSelect';

interface FilterOptionsCollapseContentProps {
    opened: boolean;
    filters: IFilters;
    onFilterChange: (type: string, selectedValues: number[]) => void;
    onRequestedFromChange: (date: Date | null) => void;
    onRequestedToChange: (date: Date | null) => void;
}

const FilterOptionsCollapseContent: React.FC<FilterOptionsCollapseContentProps> = ({ opened, filters, onFilterChange, onRequestedFromChange, onRequestedToChange }) => {
    return (
        <Collapse in={opened}>
            <Grid gutter={{ base: 5, xs: 'md', md: 'xl' }} mb="2rem">
                <Grid.Col span={{ base: 12, md: 3 }}>
                    <FilterOptionsMultiSelect
                        label="Region"
                        data={Object.entries(filters.portals).map(([key, value]) => ({
                            value: key,
                            label: value.toUpperCase(),
                        }))}
                        onChange={(value) => onFilterChange('portalIds', value.map(v => parseInt(v)))}
                        placeholder="Select regions"
                    />
                    <FilterOptionsMultiSelect
                        label="Organization"
                        data={Object.entries(filters.organizations).map(([key, value]) => ({
                            value: key,
                            label: value,
                        }))}
                        onChange={(value) => onFilterChange('organizationIds', value.map(v => parseInt(v)))}
                        placeholder="Select organizations"
                        mt="1rem"
                    />
                </Grid.Col>

                <Grid.Col span={{ base: 12, md: 3 }}>
                    <FilterOptionsMultiSelect
                        label="Service"
                        data={Object.entries(filters.services).map(([key, value]) => ({
                            value: key,
                            label: value,
                        }))}
                        onChange={(value) => onFilterChange('serviceIds', value.map(v => parseInt(v)))}
                        placeholder="Select services"
                    />
                    <FilterOptionsMultiSelect
                        label="Area of interest"
                        data={Object.entries(filters.aois).map(([key, value]) => ({
                            value: key,
                            label: value,
                        }))}
                        onChange={(value) => onFilterChange('aoiIds', value.map(v => parseInt(v)))}
                        placeholder="Select areas of interest"
                        mt="1rem"
                    />
                    <FilterOptionsMultiSelect
                        label="Study"
                        data={Object.entries(filters.studies).map(([key, value]) => ({
                            value: key,
                            label: value,
                        }))}
                        onChange={(value) => onFilterChange('studyIds', value.map(v => parseInt(v)))}
                        placeholder="Select studies"
                        mt="1rem"
                    />
                </Grid.Col>

                <Grid.Col span={{ base: 12, md: 3 }}>
                    <FilterOptionsMultiSelect
                        label="In Charge"
                        data={Object.entries(filters.caseAnalysts).map(([key, value]) => ({
                            value: key,
                            label: value,
                        }))}
                        onChange={(value) => onFilterChange('inChargeIds', value.map(v => parseInt(v)))}
                        placeholder="Select people in charge"
                    />
                    <FilterOptionsMultiSelect
                        label="Reviewer"
                        data={Object.entries(filters.caseAnalysts).map(([key, value]) => ({
                            value: key,
                            label: value,
                        }))}
                        onChange={(value) => onFilterChange('reviewerIds', value.map(v => parseInt(v)))}
                        placeholder="Select reviewers"
                        mt="1rem"
                    />
                </Grid.Col>

                <Grid.Col span={{ base: 12, md: 3 }}>
                    <FilterOptionsDateInput
                        label="Requested from"
                        onChange={onRequestedFromChange}
                    />
                    <FilterOptionsDateInput
                        label="Requested to"
                        onChange={onRequestedToChange}
                        mt="1rem"
                    />
                </Grid.Col>
            </Grid>
        </Collapse>
    );
};

export default FilterOptionsCollapseContent;
