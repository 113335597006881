import { CloseButton, Flex, rem, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React from 'react';

interface SearchNameInputProps {
    searchName: string;
    onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    clearSearch: () => void;
}

const SearchNameInput: React.FC<SearchNameInputProps> = ({ searchName, onSearchChange, clearSearch }) => {
    return (
        <Flex>
            <TextInput
                placeholder="Search by name"
                value={searchName}
                onChange={onSearchChange}
                leftSection={<IconSearch style={{ width: rem(18), height: rem(18), color: '#fff' }} />}
                rightSection={
                    <CloseButton
                        aria-label="Clear search input"
                        onClick={clearSearch}
                        style={{ color: '#fff' }}
                    />
                }
                style={{ width: '350px' }}
            />
        </Flex>
    );
};

export default SearchNameInput;
