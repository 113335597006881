import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Les clés et les traductions peuvent être stockées dans des fichiers JSON séparés
const resources = {
  en: {
    translation: {
      welcome: 'Welcome to my app!',
      language:'Language',
      // ... autres traductions
    },
  },
  fr: {
    translation: {
      welcome: 'Bienvenue dans mon application !',
      language:'Langue',
      // ... autres traductions
    },
  },
};

i18n
  .use(initReactI18next) // les plugins init i18next
  .init({
    resources,
    lng: 'en', // langue par défaut
    interpolation: {
      escapeValue: false, // ne pas échapper les valeurs interprétées par react
    },
  });

export default i18n;
