import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import i18n from './configs/i18n'; // importer le fichier de configuration i18n
import './css/index.css';

import reportWebVitals from './reportWebVitals';
import { resolver, theme } from './theme';


const root = ReactDOM.createRoot(document.getElementById('root'));


async function enableMocking() {
  if (process.env.REACT_APP_API_MOCK === 'true') {
    const { worker } = await import('./mocks/browser')
    return worker.start()
  }
}

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <MantineProvider theme={theme} defaultColorScheme="dark" cssVariablesResolver={resolver}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </MantineProvider>
    </React.StrictMode>
  );
})



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
