import { Badge, Button, Flex } from '@mantine/core';
import React from 'react';
import IStatusCounter from '../../types/StatusCounter';

interface FilterStateButtonsProps {
    allStates: string[];
    selectedStates: string[];
    isAllSelected: boolean;
    onStateChange: (state: string | string[]) => void;
    statusCounter: IStatusCounter;
}

const FilterStateButtons: React.FC<FilterStateButtonsProps> = ({ allStates, selectedStates, isAllSelected, onStateChange, statusCounter }) => {
    const capitalizeState = (state: string) => {
        return state.charAt(0) + state.slice(1).toLowerCase();
    };
    return (
        <Flex>
            <Button
                variant="default"
                style={{ opacity: isAllSelected ? 1 : 0.5 }}
                onClick={() => onStateChange('All')}
                mr="1rem"
            >
                All <Badge ml="8px">{statusCounter.all}</Badge>
            </Button>
            <Button.Group>
                {allStates.map((state) => (
                    <Button
                        variant="default"
                        style={{ opacity: selectedStates.includes(state) ? 1 : 0.5 }}
                        key={state}
                        onClick={() => onStateChange(state)}
                    >
                        {capitalizeState(state)} <Badge ml="8px">{statusCounter[state.toLowerCase() as keyof IStatusCounter]}</Badge>
                    </Button>
                ))}
            </Button.Group>
        </Flex>
    );
};

export default FilterStateButtons;
