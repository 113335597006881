import { MultiSelect } from '@mantine/core';
import React from 'react';
import classes from './FilterOptions.module.css';

interface FilterOptionsMultiSelectProps {
    label: string;
    data: { value: string; label: string }[];
    defaultValue?: string[];
    onChange?: (value: string[]) => void;
    placeholder: string;
    mt?: string;
}

const FilterOptionsMultiSelect: React.FC<FilterOptionsMultiSelectProps> = ({ label, data, defaultValue, onChange, placeholder, mt = '0' }) => {

    return (
        <MultiSelect
            classNames={{ pill: classes.pill, section: classes.section, input: classes.input, dropdown: classes.dropdown }}
            label={label}
            data={data}
            defaultValue={defaultValue}
            onChange={onChange}
            hidePickedOptions
            clearable
            placeholder={placeholder}
            mt={mt}
        />
    );
};

export default FilterOptionsMultiSelect;
