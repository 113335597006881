import { Button, Container, Overlay, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_URL } from '../../configs/http-common';
import { useAuth } from '../../contexts/AuthContext';
import classes from './Login.module.css';


export function Login() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { user, login } = useAuth();

  // Redirect to dashboard if user is already authenticated
  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const token = params.get('token');
    console.log(token); // Check if the token is correctly extracted

    if (token) {
      login(token);
      navigate("/dashboard");
    }
  }, [search, login, navigate]);

  return (
    <div className={classes.hero}>
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      />
      <Container className={classes.container} size="md">
        <Title className={classes.title}>inHEART Portal CORE</Title>

        <Button size="xl" radius="xl" className={classes.control} variant='primary' component="a" href={LOGIN_URL}>
          Login to inHEART Portal
        </Button>
      </Container>
    </div>
  );
}

export default Login
